import { useState } from 'react';
import SongItem, { SongItemProps } from "./SongItem";
import updateClipboard, { PopupProperty } from './utility';

interface SongListProps {
  songs: SongItemProps[];
  // onTap?: React.Dispatch<React.SetStateAction<PopupProperty>>;
  onTap?: (e: PopupProperty) => void,
}


function SongList(props: SongListProps) {
  const [condition, updateCondition] = useState('');
  return (
    <div className="relative overflow-x-auto bg-gray-200 dark:bg-black px-6 py-4 rounded-md">
      <div className='flex flex-row py-4'>

        {/* search bar */}
        <input
          className='flex flex-auto rounded border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6]'
          type="search"
          placeholder='搜索'
          value={condition}
          onChange={(e) => updateCondition(e.target.value)}
        />
        {/* lucky button bar */}
        <button
          className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2'
          onClick={() => {
            let randomIndex = Math.floor(Math.random() * props.songs.length)
            let randomTitle = props.songs[randomIndex].title

            updateClipboard(randomTitle).then(() => {
              if (props.onTap) {
                props.onTap({ isDisplayed: true, message: randomTitle })
              }
            })
          }}
        >
          lucky
        </button>
      </div>
      <table className="w-full text-left table-auto ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-4">
              曲名
              {/* col 1 */}
            </th>
            <th scope="col" className="px-6 py-4 hidden md:table-cell">
              艺术家
              {/* col 2 */}
            </th>
            <th scope="col" className="px-6 py-4 hidden md:table-cell">
              语言
              {/* col 3 */}
            </th>
          </tr>
        </thead >
        <tbody>
          {
            props
              .songs
              .filter((e) => e.title.indexOf(condition) >= 0)
              .map((song) =>
                <SongItem onTap={props.onTap} {...song} />
              )
          }
        </tbody>
      </table >
    </div >
  );
}

export default SongList
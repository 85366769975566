export interface PopupProperty {
  isDisplayed: boolean;
  message: string;
}

function updateClipboard(title: string): Promise<void> {
  let text = `点歌 ${title}`
  if (navigator.clipboard === undefined) {

    unsecuredCopyToClipboard(text)
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
  return navigator.clipboard.writeText(text).then(
    () => {

    },
    () => {
      /* clipboard write failed */
      console.log('set failed')
    },
  );
}

function unsecuredCopyToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
  }
  document.body.removeChild(textArea);
}

export default updateClipboard;
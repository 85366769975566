import './components.css';
import config from '../config'

interface PopupProps {
  message: string
}

function Popup(props: PopupProps) {
  return (
    <div className='min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover'>
      <div className="absolute bg-black opacity-80 inset-0 z-1"></div>
      <div
        className="bg-white flex flex-col shadow position-fixed h-32 w-5/6 md:w-2/3 ld:w-1/3 content-center rounded-md top-1/2 z-0 "
        onClick={(e) => {
          let url = config.liveRoomPrefix + config.userId;
          window.open(url, "_blank", "noreferrer");
        }}
      >
        <div className="flex flex-auto" />
        <div className="text-gray-600">
          <p className='text-base py-6'>{props.message}</p>
          <p className='text-xs'>复制成功, 点我直达直播间</p>
        </div>
        <div className="flex flex-auto" />
        {/* bottom bar */}
        <div className="h-2 rounded-md" >
          <div className="progress-bar rounded-md"></div>
        </div>
      </div>
    </div>
  );
}

export default Popup;

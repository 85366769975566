import React from 'react';
import { useState } from 'react';
import './App.css';
import Header from './components/Header';
import SongList from './components/SongList';
import { SongItemProps } from './components/SongItem';
import Popup from './components/Popup';
import { PopupProperty } from './components/utility';
import config from './config';

function App() {
  // const [isDisplayed, setIsDisplayed] = useState(false);
  const [popupChanged, setIsDisplayed] = useState<PopupProperty | undefined>();
  // useEffect(() => {
  //   navigator.permissions.query({ name: "clipboard-write" as PermissionName }).then((result) => {
  //     if (result.state === "granted" || result.state === "prompt") {
  //       /* write to the clipboard now */
  //     }
  //     console.log('permission status: ', result.state);
  //   });
  //   return () => console.log("Cleanup..");
  // }, []);



  let songs: SongItemProps[] = config.songs.map<SongItemProps>((e) => {
    return { title: e }
  });
  // for (var i = 0; i < 100; i++) {
  //   songs.push({
  //     title: `col ${i}`,
  //     artist: `col ${i}`,
  //     language: `col ${i}`,
  //     price: i,
  //     isSuggested: false,
  //   })
  // }

  return (
    <div className="App bg-gray-200 dark:bg-black">
      <Header />
      <SongList
        songs={songs}
        onTap={async (v) => {
          setIsDisplayed(v)
          setTimeout(() => setIsDisplayed({ isDisplayed: false, message: '' }), 5000);
        }}
      />

      {/* <Popup /> */}

      {popupChanged?.isDisplayed ? <Popup message={popupChanged.message} /> : null}

    </div>
  );
}



export default App;

import updateClipboard, { PopupProperty } from "./utility";

export interface SongItemProps {
  title: string;
  artist?: string;
  language?: string;
  price?: number;
  isSuggested?: boolean;
  // onTap?: React.Dispatch<React.SetStateAction<PopupProperty>>;
  onTap?: (e: PopupProperty) => void,
}

function SongItem(props: SongItemProps) {
  let key = props.title + props.artist + props.language
  console.log(`Item.key: ${key}`)
  return (
    <tr
      className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 font-medium text-base text-gray-900 whitespace-nowrap dark:text-white'
      key={key}
      onClick={() => {
        updateClipboard(props.title).then(() => {
          if (props.onTap) {
            props.onTap({ isDisplayed: true, message: props.title })
          }
        })
      }}
    >
      <td className='px-6 py-3 '>{props.title}</td>
      <td className='px-6 py-3 font-light hidden md:table-cell'>{props.artist}</td>
      <td className='px-6 py-3 font-light hidden md:table-cell'>{props.language}</td>
    </tr>
  );
}


export default SongItem
import config from '../config'

export interface HeaderProps {

}

function Header(props: HeaderProps) {
  const introduction = config.intro
  // let imageUrl = 'https://33fa1ur95-7fg4oep35.sentry.dev/static/41d51313d6bd867db165b559f22988dd/nisanthan-nanthakumar-portrait.png'
  let imageUrl = config.avatarUrl

  return (
    <div className="round-sm dark:text-white px-6 py-4 flex flex-col sm:flex-row items-center">
      {getAvatarDiv(imageUrl)}
      <p className='px-4 py-4'>{introduction}</p>
    </div>
  );
}

function getAvatarDiv(imageUrl?: string): JSX.Element | null {
  if (imageUrl?.startsWith('https') || imageUrl?.startsWith('./')) {
    return (
      // <div className="inline-block rounded-full bg-white w-36 h-36 object-cover sm:w-full sm:h-full flex-grow">
      <div className="inline-block rounded-full bg-white w-36 ">
        <img className="w-36 rounded-full shadow-lg" src={imageUrl} alt='avatar' />
      </div>
    )
  }
  return null
}

export default Header
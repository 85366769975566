const config = {
  userId: '30760601',
  liveRoomPrefix: 'https://live.bilibili.com/',
  // avatarUrl: 'https://33fa1ur95-7fg4oep35.sentry.dev/static/41d51313d6bd867db165b559f22988dd/nisanthan-nanthakumar-portrait.png',
  avatarUrl: './avatar.jpg',
  intro: '最爱吃的是，深海小鱼仔~！✧(≖ ◡ ≖✿)~欢迎来一起玩耍！',
  songs: [
    "山花寻海树",
    "爱的魔法",
    "Wonderful U",
    "QueenCard",
    "雪落下的声音",
    "不一样又怎样",
    "笼",
    "白头吟",
    "痒",
    "湘桥月",
    "探窗",
    "海洋之心",
    "气球",
    "黑发尤物",
    "丝路",
    "蝴蝶泉边",
    "雨爱",
    "爱丫爱丫",
    "走马",
    "来日方长",
    "丝路",
    "夏天的风",
    "爱你",
    "诗人漫步",
    "勇气",
    "睫毛弯弯",
    "暖暖",
    "爸爸妈妈",
    "空白",
    "昨日青空",
    "阿刁",
    "荷塘月色",
    "红豆",
    "梦的光点",
    "如愿",
    "你啊你啊",
    "恶作剧",
    "没离开过",
    "心引力",
    "勾指起誓",
    "欧若拉",
    "恋爱告急",
    "春日宴",
    "奢香夫人",
    "白白的",
    "一眼万年",
    "怕什么",
    "在树上唱歌",
    "猪猪侠",
    "别找我麻烦",
    "九张机",
    "百分百恋爱",
    "异想记",
    "如果有来生",
    "日不落",
    "心愿便利贴",
    "红昭愿",
    "爱要坦荡荡",
    "下雨天",
    "淋雨一直走",
    "向云端",
    "我的未来式",
    "卡路里",
    "如果有来生",
    "第一天",
    "下一个天亮",
    "我想爱",
    "看月亮爬上来",
    "锦鲤抄",
    "马德里不思议",
    "樱花草",
    "粉红色的回忆",
    "黄梅戏",
    "是妈妈是女儿",
    "蜀道难",
    "火红的萨日朗",
    "我知道你很难过",
    "亲爱的那不是爱情",
    "知否知否应是绿肥红瘦",
    "一个像夏天一个像秋天",
    "Glassy sky",
    "I see the light",
    "Reflection",
    "I Wanna Know",
    "99次我爱他",
    "你不是真正的快乐"
  ],
}

export default config